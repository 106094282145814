<template>
  <the-wrapper :isSpinner="isSpinner">
    <slot>
      <ServicesDescription :isFooter="isFooter">
        <template #title>Тарифы на междугородние звонки</template>
        <template #body>
          <b-row>
            <b-col sm="10" md="8" lg="12" xl="7">
              <b-form-group label="Выберите направление:" label-cols-sm="5" label-cols-xl="5">
                <BaseSelect v-model="selected" :options="options"/>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="dflex sm-align-end lg-align-start">
            <BaseButton @click="showTariffs">
              <template #text>
                Показать
              </template>
            </BaseButton>
          </div>
        </template>
      </ServicesDescription>
      <br />
      <base-table v-if="tableItems.length" :fields="fields" :items="tableItems">
        <template #headerText> Стоимость звонков {{ selected }} </template>
      </base-table>
    </slot>
  </the-wrapper>
</template>

<script>
import TheWrapper from '@/components/shared/TheWrapper.vue';
import ServicesDescription from '@/components/shared/ServicesDescription.vue';
import BaseTable from '@/components/shared/BaseTable.vue';
import BaseSelect from '@/components/shared/inputs/BaseSelect.vue';
import BaseButton from '@/components/shared/buttons/BaseButton.vue';
import { getDetailsWithQuery } from '@/api/common';
import { catchFunction } from '@/additional_helpers/helpers';
import URL from '@/basUrl';
import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      isSpinner: true,
      isFooter: false,
      id: this.$route.query.id,
      area: '',
      selected: '',
      options: '',
      tableItems: [],
      fields: [
        { key: 'AreaName', label: 'Направление' },
        { key: 'RtsArcode', label: 'Код' },
        { key: 'RtsValue', label: 'Руб./мин.' }
      ]
    };
  },
  components: {
    TheWrapper,
    ServicesDescription,
    BaseTable,
    BaseButton,
    BaseSelect
  },

  beforeRouteEnter(to, from, next) {
    return to.query.id ? next() : next({ name: 'Numbers' });
  },

  created() {
    getDetailsWithQuery(URL.INTERCITY_LIST, `card=${this.id}`)
      .then((result) => {
        this.options = result.data;
        this.SHOW_LOADING(false);
      })
      .catch((e) => catchFunction(e));
  },

  methods: {
    ...mapMutations({
      SHOW_LOADING: 'SHOW_LOADING'
    }),

    showTariffs() {
      getDetailsWithQuery(URL.INTERCITY_TARIF, `card=${this.id}&area=${this.selected}`)
        .then((result) => {
          this.tableItems = result.data;
        })
        .catch((e) => catchFunction(e));
    }
  }
};
</script>

<style></style>
